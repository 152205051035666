import { shuffle } from "shuffle-seed";

/**
 * When a step's isRandomized property is true, randomize the order of its items using a specified random seed value.
 * @param {Step} step - The survey step for which the order of items is being randomized
 * @param {Integer} randomSeed - The respondent's random seed to use in ordering materials
 * @return {Step} - Returns a new Step object identical the original but with the modified order of items
 */
function applyRandomSeedToStep(step, randomSeed) {
    // When there's no randomization, return the unmodified step
    if (!step?.isRandomized) return step;

    const shuffledItems = shuffle(step.items, randomSeed);
    return {...step, items: shuffledItems}
}

/**
 * For a given survey, randomize the order of items within all steps that have the isRandomized property set to true.
 * @param {Integer} randomSeed - The respondent's random seed to use in ordering items within the survey
 * @param {Survey.Survey} survey - The survey being randomized
 * @return {Survey.Survey} - Returns a new Survey object identical the original but with the modified Step objects
 */
export function applyRandomSeedToSurvey(randomSeed, survey) {
    // Check that there is a random seed and items to shuffle
    if (!randomSeed || !survey?.steps?.length) return survey;

    const stepsAfterItemsShuffled = survey.steps.map((step) =>
        applyRandomSeedToStep(step, randomSeed)
    );
    return {...survey, steps: stepsAfterItemsShuffled}
}

/**
 * Find the index of the first step on the survey that lacks a completion progression object.
 * @param {Progression[]} progressions - The list of the progression objects describing materials started/completed thus far
 * @param {Survey.Survey} survey - The survey being randomized
 * @return {Integer} - Returns the index of the first uncompleted step on the survey. When there are no uncompleted
 *                     steps, returns -1.
 */
export function findIndexOfFirstUncompletedStep(progressions, survey) {
    // When there are no steps in the survey, there are no uncompleted steps
    if (!survey?.steps?.length || !progressions?.length) return -1;

    return survey.steps.findIndex((step) => !progressions.some((progression) =>
        progression.step === step.id &&
        progression.type === 'C' &&
        !progression.deletedAt
    ));
}

/**
 * For a given step, remove any fields related to saving the respondent's login code
 * @param {Step} step - The survey step for which fields are being filtered
 * @return {Step} - Returns a new Step object identical the original but with login code fields filtered out
 */
function removeLoginCodeFieldsFromStep(step) {
    const filteredFields = step.fields.filter((field) => field.category !== 'LER');
    return {...step, fields: filteredFields}
}

/**
 * For a given survey, remove any fields related to saving the respondent's login code
 * @param {Survey.Survey} survey - The survey from which to remove login code fields
 * @return {Survey.Survey} - Returns a new Survey object identical the original but with the modified Step objects
 */
export function removeLoginCodeFieldsFromSurvey(survey) {
    const stepsAfterFieldsFiltered = survey.steps.map((step) => removeLoginCodeFieldsFromStep(step));
    return {...survey, steps: stepsAfterFieldsFiltered}
}