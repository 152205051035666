import { useEffect } from "react";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { elementIndexAtom, stepIndexAtom } from '_atoms';
import { indexOfFirstUncompletedStepSelector, surveySelector } from "_selectors";

/**
 * When a survey first loads, set the item and step index. Show the first element of the first uncompleted step.
 * When there are no uncompleted steps, show the first element of the first step.
 */
const useSurvey = () => {
    /** @type number */
    const indexOfFirstUncompletedStep = useRecoilValue(indexOfFirstUncompletedStepSelector);
    /** @type function */
    const resetElementIndex = useResetRecoilState(elementIndexAtom);
    /** @type Dispatch<SetStateAction<Survey.Index>> */
    const setStepIndex = useSetRecoilState(stepIndexAtom);
    /** @type {Survey.Survey|T} */
    const survey = useRecoilValue(surveySelector);

    useEffect(() => {
        // Only proceed when there is an active survey with step information available (so we can know the
        // completion status of those steps)
        if (!survey?.steps?.length) return;

        setStepIndex((prev) => {
            // When advancing past the last step, don't alter the step index. Other hooks will reset indices.
            if (prev.value >= survey?.steps?.length) return prev

            return (
                /** @type Survey.Index */
                {
                    direction: 1,
                    value: Math.max(indexOfFirstUncompletedStep, 0)
                }
            )
        })
    }, [indexOfFirstUncompletedStep, resetElementIndex, setStepIndex, survey]);
}

export { useSurvey };