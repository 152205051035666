import { selector } from "recoil";
import { progressionsAtom, respondentAtom } from "_atoms";
import { batchGetQuery } from "_queries/batch-queries";

/**
 * Fetch the Batch object from the API based on the batch property of the respondent atom.
 */
const batchSelector = selector({
    key: 'batch',
    get: async ({get}) => /** @type ?Batch */ {
        /** @type string */
        const batchId = get(batchIdSelector);
        /** @type AbortController */
        const controller = new AbortController();

        if (!batchId) return null;

        /** @type Promise */
        return batchGetQuery(batchId, controller).then((axiosResponse) => {
            /** @type {Batch} - Return the batch object contained in the response data */
            return axiosResponse.data;
        }).catch((axiosError) => {
            const message = axiosError?.response?.data?.message || axiosError?.response?.statusText || axiosError.message;
            throw new Error(message);
        });
    }
});

/**
 * Return the batch id.
 */
const batchIdSelector = selector({
    key: 'batchId',
    get: ({get}) => {
        /** @type Respondent */
        const respondent = get(respondentAtom);

        /** @type ?string */
        return respondent?.batch;
    }
});

/**
 * Return the Debrief object for the batch.
 */
const debriefSelector = selector({
    key: 'debrief',
    get: ({get}) => {
        /** @type {Batch|T} */
        const batch = get(batchSelector);

        /** @type ?Debrief */
        return batch?.debrief;
    }
});

/**
 * Determine if the respondent has a progression marking the batch as completed.
 */
const hasFinishedBatchSelector = selector({
    key: 'hasFinishedBatch',
    get: ({get}) => {
        /** @type {Batch|T} */
        const batch = get(batchSelector);
        /** @type Progression[] */
        const progressions = get(progressionsAtom);

        // When there is no batch info or no progressions, the batch cannot possibly be completed
        if (!batch?.id || !progressions?.length) return false;

        /** @type {boolean} - Return true when the batch has a completion object */
        return progressions.findIndex((progression) =>
            progression?.batch === batch?.id &&
            progression?.type === 'C' &&
            !progression?.deletedAt
        ) > -1;
    }
});

/**
 * Determine if the respondent has a progression marking the batch as started.
 */
const hasStartedBatchSelector = selector({
    key: 'hasStartedBatch',
    get: ({get}) => {
        /** @type {Batch|T} */
        const batch = get(batchSelector);
        /** @type Progression[] */
        const progressions = get(progressionsAtom);

        // When there is no batch info or no progressions, the batch cannot possibly be started
        if (!batch?.id || !progressions?.length) return false;

        /** @type {boolean} - Return true when the batch has a start progression */
        return progressions.findIndex((progression) =>
            progression?.batch === batch?.id &&
            progression?.type === 'S' &&
            !progression?.deletedAt
        ) > -1;
    }
});

/**
 * Return the Welcome object for the batch.
 */
const welcomeSelector = selector({
    key: 'welcome',
    get: ({get}) => {
        /** @type {Batch|T} */
        const batch = get(batchSelector);

        /** @type ?Welcome */
        return batch?.welcome;
    }
});

/**
 * Whether the batch has a welcome message to display.
 */
const hasWelcomeSelector = selector({
    key: 'hasWelcome',
    get: ({get}) => {
        /** @type {Batch|T} */
        const batch = get(batchSelector);

        /** @type ?Welcome */
        return !!batch?.welcome?.body;
    }
});

export {
    batchSelector,
    batchIdSelector,
    debriefSelector,
    hasFinishedBatchSelector,
    hasStartedBatchSelector,
    hasWelcomeSelector,
    welcomeSelector
};