import { atom } from "recoil";

/**
 * A list of Progression objects indicating which surveys and steps have been started and completed.
 */
const progressionsAtom = atom({
    key: 'progressions',
    default: []
});

export { progressionsAtom };