import { atom } from 'recoil';

/**
 * Which step on the active survey should be rendered. Also describes the direction of movement (1 or -1).
 */
const stepIndexAtom = atom({
    key: 'stepIndex',
    default: (
        /** @type Survey.Index */
        {
            direction: 1,
            value: 0
        }
    )
});

export { stepIndexAtom };