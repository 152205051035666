import { useCallback } from "react";
import { useResetRecoilState } from "recoil";
import { useErrorBoundary } from "react-error-boundary";
import { surveyIdAtom, loginFormAtom, loginStatusAtom, respondentAtom } from "_atoms";

/**
 * Actions to handle a failed axios API request
 */
const useAxiosError = () => {
    /** @type function */
    const resetLoginForm = useResetRecoilState(loginFormAtom);
    /** @type function */
    const resetLoginStatus = useResetRecoilState(loginStatusAtom);
    /** @type function */
    const resetRespondent = useResetRecoilState(respondentAtom);
    /** @type function */
    const resetSurveyId = useResetRecoilState(surveyIdAtom);

    const { showBoundary } = useErrorBoundary();

    /**
     * Handle the results of a failed POST request from the API.
     * @param {AxiosError} axiosError - The axios error object returned after the API call
     * @return {string} Returns the error message from the response.data.message or response.statusText properties
     */
    const handleAxiosError = useCallback((axiosError) => {
        if ([401, 403].includes(axiosError?.response?.status)) {
            // Auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            sessionStorage.removeItem('session');
            resetSurveyId();
            resetLoginForm();
            resetLoginStatus();
            resetRespondent();
        } else if (axiosError?.code === 'ERR_CANCELED') {
            // Don't show anything when an axios request is simply canceled
            return;
        }

        // Show and return the error message
        const error = axiosError?.response?.data?.message || axiosError?.response?.statusText || axiosError.message || axiosError;
        console.log(axiosError);
        showBoundary(error);
    }, [showBoundary,  resetSurveyId, resetLoginForm, resetLoginStatus, resetRespondent])

    return { handleAxiosError }
};

export default useAxiosError;