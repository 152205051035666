import { atom } from 'recoil';

/**
 * Boolean of whether a login code is being requested/sent to the user.
 */
const isSendingLoginCodeAtom = atom({
    key: 'isSendingLoginCode',
    default: false
});

export { isSendingLoginCodeAtom };