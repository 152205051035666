import { selector } from "recoil";
import { languagesGetQuery } from "_queries/languages-queries";
import { loginCodeAtom } from "_atoms";

/**
 * When there is an active survey id or a single survey being administered in the batch, fetch the corresponding
 * survey information from the API.
 */
const languagesSelector = selector({
    key: 'languages',
    get: async () => /** @type Language[] */ {
        /** @type AbortController */
        const controller = new AbortController();

        /** @type Promise */
        return languagesGetQuery(controller).then((axiosResponse) => {
            /** @type {Language[]} - The list of language objects returned from the API */
            return axiosResponse.data.results;
        }).catch((axiosError) => {
            const message = axiosError?.response?.data?.message || axiosError?.response?.statusText || axiosError.message;
            throw new Error(message);
        });
    }
});

/**
 * When there is an active survey id or a single survey being administered in the batch, fetch the corresponding
 * survey information from the API.
 */
const availableLanguagesSelector = selector({
    key: 'availableLanguages',
    get: async ({get}) => /** @type Language[] */ {
        /** @type {LoginCode|T} */
        const loginCode = get(loginCodeAtom);
        /** @type {Language[]|T} */
        const languages = get(languagesSelector);

        return languages?.filter(({id: id1}) =>
            loginCode?.languages?.some((id2) => id2 === id1)
        );
    }
});

export { availableLanguagesSelector, languagesSelector };