import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { useSetRecoilState, useResetRecoilState } from "recoil";
import { useErrorBoundary } from "react-error-boundary";
import {
    answersAtom,
    surveyIdAtom,
    demographicsAtom,
    loginCodeAtom,
    loginFormAtom,
    loginStatusAtom,
    progressionsAtom,
    respondentAtom,
    userAtom
} from '_atoms';
import {setLoginStatusAfterFailedLogin, storeSessionInfo} from "_helpers/login-helpers";
import { loginGetQuery, loginPostQuery } from "_queries/login-queries";

/**
 * Upon first loading the app, check if login info is stored in the session or url params. When info is stored in the
 * session, reload the app state from the API. When url params have a login code value, post a new login.
 */
const useSession = () => {
    /** @type {Dispatch<SetStateAction<LoginCode>>} */
    const setLoginCode = useSetRecoilState(loginCodeAtom);
    /** @type Dispatch<SetStateAction<LoginForm>> */
    const setLoginForm = useSetRecoilState(loginFormAtom);
    /** @type Dispatch<SetStateAction<LoginStatus>> */
    const setLoginStatus = useSetRecoilState(loginStatusAtom);
    /** @type function */
    const resetAnswers = useResetRecoilState(answersAtom);
    /** @type function */
    const resetSurveyId = useResetRecoilState(surveyIdAtom);
    /** @type function */
    const resetDemographics = useResetRecoilState(demographicsAtom);
    /** @type function */
    const resetLoginForm = useResetRecoilState(loginFormAtom);
    /** @type function */
    const resetLoginStatus = useResetRecoilState(loginStatusAtom);
    /** @type function */
    const resetProgressions = useResetRecoilState(progressionsAtom);
    /** @type function */
    const resetRespondent = useResetRecoilState(respondentAtom);
    /** @type function */
    const resetUser = useResetRecoilState(userAtom);

    /** @type URLSearchParams */
    const [ searchParams ] = useSearchParams();

    /** Hook provided by react-error-boundary for sharing errors with the nearest boundary */
    const { showBoundary } = useErrorBoundary();

    // The hook should only run once upon initial rendering of the React app. All parameters are passed by reference
    // so that updates to the parameters do not trigger the hook to run again.
    /** @type MutableRefObject<URLSearchParams> */
    const refSearchParams = useRef(searchParams);

    useEffect(() => {
        /** @type {AbortController} - Use an abort controller to cancel API requests if the component unmounts */
        const controller = new AbortController();
        /** @type {Survey.Session} - The current session information */
        const session = JSON.parse(sessionStorage.getItem('session'));
        /** @type string */
        const cookieLoginId = getCookie("loginid");

        /**
        * Retrieve the value of a cookie
        * @param {string} key - The name of the cookie
        * @return {string}
        */
        function getCookie(key) {
            const value = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
            return value ? value.pop() : "";
        }

        /**
        * Handle the results of a failed request from the API.
        * @param {AxiosError|AxiosResponse} axiosError - The axios error object returned after the API call
        * @return {void}
        */
        function handleFailure(axiosError) {
            try {
                if (axios.isCancel(axiosError)) return;

                // Update the login status to reflect the failures
                const data = axiosError?.response?.data || axiosError?.data;
                setLoginStatusAfterFailedLogin(data, setLoginStatus);

                // If a login code was returned, set it
                if (data?.loginCode?.id) {
                    setLoginCode(data.loginCode);
                }

                // Reset the app state back to its default status
                resetAnswers();
                resetDemographics();
                resetProgressions();
                resetRespondent();
                resetSurveyId();
                resetUser();
            } catch(error) {
                console.log({'error': error, 'axiosError': axiosError});
                sessionStorage.removeItem('session');

                //Display the nearest error boundary
                const errorMessage = axiosError?.response?.data?.message || axiosError?.data?.message || axiosError;
                showBoundary(errorMessage);
            }
        }

       /**
        * Determine the login code object to save to the app state. When the login code returned from the API contains
        * a value, use it. Otherwise, use any login code value included in URL parameters then delete the URL param.
        * @param {LoginCode} loginCodeFromApi - The login code object returned from the API
        * @return {LoginCode} - The login code object to save to app state, which includes value info from url params
        */
        function mergeUrlParamsIntoLoginCode(loginCodeFromApi) {
            /** @type string */
            const loginCodeValueInUrl = refSearchParams.current.get('loginCode') || '';
            /** @type string */
            const loginCodeValueToUse = loginCodeFromApi?.value || loginCodeValueInUrl;

            // Remove the login code parameter from the url
            refSearchParams.current.delete('loginCode');

            return (
                /** @type LoginCode */
                {...loginCodeFromApi, value: loginCodeValueToUse}
            );
        }

        /**
        * Handle the results of a successful request from the API.
        * @param {AxiosResponse} axiosResponse - The axios response object returned after the API call
        * @return {void}
        */
        function handleSuccess(axiosResponse) {
            try {
                const data = axiosResponse?.data;
                const loginCode = mergeUrlParamsIntoLoginCode(data.loginCode)
                storeSessionInfo(data?.id, data?.token?.access, data?.token?.refresh);
                setLoginCode(loginCode);
                setLoginStatus(
                    /** @type LoginStatus */
                    {
                        currentStatusCode: data?.status,
                        errorMessage: '',
                        previousStatusCodes: []
                    }
                );
            } catch(error) {
                handleFailure(axiosResponse);
            }
        }

        if (!!session?.id || !!cookieLoginId) {
            // When login info is present in the session, use session info to retrieve the info for the given login id
            setLoginStatus(
                /** @type LoginStatus */
                {
                    currentStatusCode: 'PL',
                    errorMessage: '',
                    previousStatusCodes: []
                }
            );
            /** @type string */
            const loginId = session?.id || cookieLoginId;

            loginGetQuery(loginId, controller).then(handleSuccess).catch(handleFailure);
        } else if (refSearchParams.current.get('loginCode')) {
            /** @type {LoginForm} - When the url params contain info to log in (a login code value), use it to log in */
            const loginForm = {
                backupOneTimePassword: null,
                customIdValue: refSearchParams.current.get('customId'),
                loginCodeValue: refSearchParams.current.get('loginCode'),
                password: null,
                oneTimePassword: null
            }
            setLoginForm(loginForm);
            refSearchParams.current.delete('loginCode');
            loginPostQuery(loginForm).then(handleSuccess).catch(handleFailure);
        }

        // When the effect unmounts, abort any active API requests
        return () => {
            controller.abort();
        };
    }, [refSearchParams, resetAnswers, resetDemographics, resetLoginForm, resetLoginStatus, resetProgressions,
        resetRespondent, resetSurveyId, resetUser, setLoginCode, setLoginForm, setLoginStatus, showBoundary]);
}

export { useSession };