import { selector } from "recoil";
import { progressionsAtom, respondentAtom, surveyIdAtom } from "_atoms";
import { surveyGetQuery } from "_queries/survey-queries";
import {
    applyRandomSeedToSurvey,
    findIndexOfFirstUncompletedStep,
    removeLoginCodeFieldsFromSurvey
} from "_helpers/survey-helpers";

/**
 * When there is an active survey id or a single survey being administered in the batch, fetch the corresponding
 * survey information from the API.
 */
const surveySelector = selector({
    key: 'survey',
    get: async ({get}) => /** @type ?Survey.Survey */ {
        /** @type string */
        const surveyId = get(surveyIdAtom);
        /** @type Respondent */
        const respondent = get(respondentAtom);
        /** @type AbortController */
        const controller = new AbortController();

        if (!surveyId || !respondent?.id) return null;

        /** @type Promise */
        return surveyGetQuery(surveyId, controller).then((axiosResponse) => {
            /** @type {Survey.Survey} - When the respondent has already saved their login code, remove fields related to saving it again */
            const survey = !respondent?.hasSavedLoginCode ? axiosResponse.data : removeLoginCodeFieldsFromSurvey(axiosResponse.data);

            /** @type {Survey.Survey} - Apply the random seed to item orders for any steps that have isRandomized = true */
            return applyRandomSeedToSurvey(respondent?.randomSeed, survey);
        }).catch((axiosError) => {
            const message = axiosError?.response?.data?.message || axiosError?.response?.statusText || axiosError.message;
            throw new Error(message);
        });
    }
});

/**
 * Determine if the respondent has a progression marking the survey as started. Returns boolean.
 */
const hasStartedSurveySelector = selector({
    key: 'hasStartedSurvey',
    get: ({get}) => /** @type boolean */ {
        /** @type {Survey.Survey|T} */
        const survey = get(surveySelector);
        /** @type Progression[] */
        const progressions = get(progressionsAtom);

        // When there is no survey info or no progressions, the survey cannot possibly be started
        if (!survey?.id || !progressions?.length) return false;

        /** @type {boolean} - Return true when the survey has a non-deleted start progression */
        return progressions.findIndex((progression) =>
            progression?.survey === survey?.id &&
            progression?.type === 'S' &&
            !progression?.deletedAt
        ) > -1;
    }
});

/**
 * Find the index of the first step on the survey that lacks a completion progression. Returns an integer.
 */
const indexOfFirstUncompletedStepSelector = selector({
    key: 'indexOfFirstUncompletedStep',
    get: ({get}) => /** @type number */ {
        /** @type Progression[] */
        const progressions = get(progressionsAtom);
        /** @type {Survey.Survey|T} */
        const survey = get(surveySelector);

        return findIndexOfFirstUncompletedStep(progressions, survey);
    }
});

/**
 * Count the number of steps in the currently active survey
 */
const numberOfStepsInSurveySelector = selector({
    key: 'numberOfStepsInSurvey',
    get: ({get}) => /** @type number */ {
        /** @type {Survey.Survey|T} */
        const survey = get(surveySelector);

        return survey?.steps?.length || null;
    }
});

export { surveySelector, hasStartedSurveySelector, indexOfFirstUncompletedStepSelector, numberOfStepsInSurveySelector };