import { atom } from "recoil";

/**
 * The id of the currently active survey. A string-formatted uuid or an empty string when there is no active
 * survey.
 */
const surveyIdAtom = atom({
  key: 'surveyId',
  default: ''
});

export { surveyIdAtom };