import { useEffect } from "react";
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from "recoil";
import {elementIndexAtom, progressionsAtom, stepIndexAtom, surveyIdAtom} from "_atoms";
import {
    batchIdSelector,
    currentStepIdSelector,
    hasStartedBatchSelector,
    hasStartedCurrentStepSelector,
    hasStartedSurveySelector,
    isTimerPausedSelector,
    isLastMaterialSelector,
    previousStepIdSelector,
    respondentIdSelector, numberOfStepsInSurveySelector
} from "_selectors";
import { createProgression, mergeProgressions } from "_helpers/progression-helpers";

/** On completing a step, append progressions of the step completed and any step being started. */
const useStepIndex = () => {
    /** @type ?string */
    const batchId = useRecoilValue(batchIdSelector);
    /** @type ?string */
    const currentStepId = useRecoilValue(currentStepIdSelector);
    /** @type boolean */
    const hasStartedSurvey = useRecoilValue(hasStartedSurveySelector);
    /** @type boolean */
    const hasStartedCurrentStep = useRecoilValue(hasStartedCurrentStepSelector);
    /** @type boolean */
    const hasStartedBatch = useRecoilValue(hasStartedBatchSelector)
    /** @type boolean */
    const isLastMaterial = useRecoilValue(isLastMaterialSelector);
    /** @type boolean */
    const isTimerPaused = useRecoilValue(isTimerPausedSelector);
    /** @type ?string */
    const previousStepId = useRecoilValue(previousStepIdSelector);
    /** @type ?string */
    const respondentId = useRecoilValue(respondentIdSelector);
    /** @type Survey.Index */
    const stepIndex = useRecoilValue(stepIndexAtom);
    /** @type ?string */
    const surveyId = useRecoilValue(surveyIdAtom);
    /** @type Dispatch<SetStateAction<Progression[]>> */
    const setProgressions = useSetRecoilState(progressionsAtom);
    /** @type number */
    const numberOfStepsInSurvey = useRecoilValue(numberOfStepsInSurveySelector);
    /** @type function */
    const resetElementIndex = useResetRecoilState(elementIndexAtom);
    /** @type function */
    const resetStepIndex = useResetRecoilState(stepIndexAtom);
    /** @type function */
    const resetSurveyId = useResetRecoilState(surveyIdAtom);

    /** When moving forward beyond a previous step, record the previous step as completed */
    useEffect(() => {
        // When the step index exceeds the number of steps on the survey, or falls below zero,
        // reset the survey id, element index, and step index.
        if (stepIndex.value >= numberOfStepsInSurvey || stepIndex.value < 0) {
            resetSurveyId();
            resetElementIndex();
            resetStepIndex();
        }

        // This hook only runs when a respondent finishes a step. Sometimes completing a step results in simultaneously
        // starting the next one. When that happens, this hook adds both progression objects simultaneously to the
        // progressions atom. Adding both simultaneously avoids competing/cancelled back-to-back API calls that would
        // happen if two separate additions were made to the progressions array.
        if (stepIndex.value < 1 || stepIndex.direction !== 1) return;

        /** @type Date */
        const createdAt = new Date();
        /** @type {?string} - When the survey hasn't yet been started, record it at started */
        const surveyStarted = hasStartedSurvey ? null : surveyId;
        /** @type {?string} - When the batch hasn't yet been started, record it as started */
        const batchStarted = hasStartedBatch ? null : batchId;
        /** @type {?string} - When the step hasn't yet been started, record it as started */
        const stepStarted = hasStartedCurrentStep ? null : currentStepId;
        /** @type Progression */
        const startProgression = createProgression(
            createdAt, respondentId, stepStarted, 'S', surveyStarted, batchStarted
        );
        /** @type {?string} - When moving beyond the last step of the survey, record the survey as completed */
        const surveyCompleted = !currentStepId ? surveyId : null;
        /** @type {?string} - When a completed survey is also the last material in the batch, record the batch as completed */
        const batchCompleted = isLastMaterial && !!surveyCompleted ? batchId : null;
        /** @type Progression */
        const completionProgression = createProgression(
            createdAt, respondentId, previousStepId, 'C', surveyCompleted, batchCompleted
        );
        /** boolean */
        const isStartingNextStep = !isTimerPaused && !!stepStarted;
        /** @type Progression[] */
        const newProgressions = isStartingNextStep ? [completionProgression, startProgression] : [completionProgression];

        setProgressions((prev) => mergeProgressions(newProgressions, prev));
    }, [batchId, currentStepId, hasStartedSurvey, hasStartedBatch, hasStartedCurrentStep, isLastMaterial,
        isTimerPaused, numberOfStepsInSurvey, previousStepId, respondentId, resetElementIndex, resetStepIndex,
        resetSurveyId, setProgressions, stepIndex, surveyId]);
};

export { useStepIndex };