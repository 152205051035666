import { atom } from 'recoil';

/**
 * The email verification code object. The value of the code was sent to the user's email address to verify ownership
 * of the given address.
 */
const emailVerificationCodeAtom = atom({
    key: 'isPendingEmailVerification',
    default:
    /** @type EmailVerificationCode */
    {
        id: '',
        createdAt: '',
        emailAddress: '',
        emailTemplate: '',
        respondent: '',
        user: ''
    }
});

export { emailVerificationCodeAtom };