import { useSetRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useErrorBoundary } from 'react-error-boundary';
import {
    answersAtom,
    demographicsAtom,
    loginCodeAtom,
    loginFormAtom,
    loginStatusAtom,
    progressionsAtom,
    respondentAtom,
    surveyIdAtom,
} from '_atoms';
import { appendToPreviousStatusCodes, setLoginStatusAfterFailedLogin, storeSessionInfo } from "_helpers/login-helpers";
import { loginPostQuery } from "_queries/login-queries";

/**
 * Actions to log in or log out.
 */
const useLoginActions = () => {
    /** @type LoginForm */
    const loginForm = useRecoilValue(loginFormAtom);
    /** @type {Dispatch<SetStateAction<LoginCode>>} */
    const setLoginCode = useSetRecoilState(loginCodeAtom);
    /** @type {Dispatch<SetStateAction<LoginStatus>>} */
    const setLoginStatus = useSetRecoilState(loginStatusAtom);
    /** @type function */
    const resetAnswers = useResetRecoilState(answersAtom);
    /** @type function */
    const resetDemographics = useResetRecoilState(demographicsAtom);
    /** @type function */
    const resetLoginForm = useResetRecoilState(loginFormAtom);
    /** @type function */
    const resetLoginStatus = useResetRecoilState(loginStatusAtom);
    /** @type function */
    const resetProgressions = useResetRecoilState(progressionsAtom);
    /** @type function */
    const resetRespondent = useResetRecoilState(respondentAtom);
    /** @type function */
    const resetSurveyId = useResetRecoilState(surveyIdAtom);

    /** Hook provided by react-error-boundary for sharing errors with the nearest boundary */
    const { showBoundary } = useErrorBoundary();

    /**
     * Handle an error from an unsuccessful API call.
     * @param {AxiosError|AxiosResponse} axiosError - The error object returned by axios
     * @return {void}
     */
    function handleFailure(axiosError) {
        try {
            // Update the login status to reflect the failures
            const data = axiosError?.response?.data || axiosError?.data;
            setLoginStatusAfterFailedLogin(data, setLoginStatus);

            // If a login code was returned, set it
            if (data?.loginCode?.id) {
                setLoginCode(data.loginCode);
            }

            // Remove any session variable
            sessionStorage.removeItem('session');

            // Reset the app state back to its default status
            resetAnswers();
            resetDemographics();
            resetProgressions();
            resetRespondent();
            resetSurveyId();
        } catch (error) {
            console.log({'error': error, 'axiosError': axiosError});
            const errorMessage = axiosError?.response?.data?.message || axiosError?.data?.message || axiosError;
            logout();                   // Reset the app state back to default so the user can try again
            showBoundary(errorMessage);  //Display the nearest error boundary
        }
    }

    /**
    * Merge login code value from the loginForm into the loginCode object returned from the API
    * @param {LoginCode} loginCodeFromApi - The login code object returned from the API
    * @return {LoginCode} - The login code object to save to app state, which includes value info from the login form
    */
    function mergeFormParamsIntoLoginCode(loginCodeFromApi) {
        /** @type string */
        const loginCodeValueInForm = loginForm?.loginCodeValue || '';
        /** @type string */
        const loginCodeValueToUse = loginCodeFromApi?.value || loginCodeValueInForm;

        return (
            /** @type LoginCode */
            {...loginCodeFromApi, value: loginCodeValueToUse}
        );
    }

    /**
     * After a successful API call, update the Recoil state values for the app
     * @param {AxiosResponse} axiosResponse - The http response object from axios
     * @return {void}
     */
    function handleSuccess(axiosResponse) {
        try {
            const data = axiosResponse?.data;
            setLoginStatus(
                /** @type LoginStatus */
                {
                    currentStatusCode: data?.status,
                    errorMessage: '',
                    previousStatusCodes: []
                }
            );
            if (!data?.token) return;
            storeSessionInfo(data?.id, data?.token?.access, data?.token?.refresh);
            /** @type LoginCode */
            const loginCode = mergeFormParamsIntoLoginCode(data.loginCode);
            setLoginCode(loginCode);
        }  catch (error) {
            handleFailure(axiosResponse);
        }
    }

    /**
     * Post a new login to the API.
     * @param {Event} [e] - A form submission event
     * @return {Promise} - Returns the axios promise
     */
    function login(e) {
        if (e) e.preventDefault();
        setLoginStatus((prev) => (
            /** @type LoginStatus*/
            {
                ...prev,
                currentStatusCode: 'PL',
                previousStatusCodes: appendToPreviousStatusCodes(prev.previousStatusCodes, prev.currentStatusCode)
            }
        ));
        const query = loginPostQuery(loginForm);
        query.then(handleSuccess).catch(handleFailure);
        return query;
    }

    /**
     * Log out, clearing App state.
     * @param {Event} [e] - A click event on a logout link
     * @return {void}
     */
    function logout(e) {
        if (e) e.preventDefault();

        sessionStorage.removeItem('session');
        resetAnswers();
        resetDemographics();
        resetLoginForm();
        resetLoginStatus();
        resetProgressions();
        resetRespondent();
        resetSurveyId();
    }

    return { login, logout }
}

export default useLoginActions;