/**
 * Add an "active" property to each option documenting whether the option is currently selected
 * @param {Option[]} options - The list of option objects for the item
 * @param {string[]} selectedOptions - The list of ids for options that are currently selected
 * @return {Option[]}
 */
export function appendActiveStatus(options, selectedOptions) {
    return options?.map((option) => {
        return {...option, active: selectedOptions.includes(option.id)}
    }) || [];
}

/**
 * Check if one of the skip groups (groups causing an item/field to be skipped) has been selected.
 * @param {string[]} skipGroupIds - The list of group/option ids that would cause the field or item to be skipped.
 * @param {Demographic[]|Answer[]} replies - The current list of replies
 * @return {boolean} - Returns true when one of the skip groups are selected.
 */
export function checkIfAnySkipGroupsAreSelected(skipGroupIds, replies) {
    if (!skipGroupIds?.length || !replies?.length) return false;

    return skipGroupIds.findIndex(
        (skipGroupId) => replies.some((demographic) => demographic.groups.includes(skipGroupId))
    ) > -1;
}

/**
 * Filter a list of options to find the ids of the ones that trigger showing the input element
 * @param {(Option|Group)[]} groups - The list of options to filter
 * @return {string[]}
 */
export function filterOptionsTriggeringInput(groups) {
    if (!groups?.length) return [];

    const optionsTriggeringInput = groups?.filter((group) => group?.triggerInput === true);
    return optionsTriggeringInput.map((option) => option.id);
}

/**
 * Get the id of the option that was the target of the click or change event
 * @param {ChangeEvent<HTMLInputElement>} e - The event triggered by the change or click
 * @param {(Option|Group)[]} options - The list of options or groups in which to search. The haystack.
 * @return {Option|Group}
 */
export function getOption(e, options) {
    const id = e.target?.value || e.target.closest("button, input")?.id || e.target.closest("label")?.htmlFor;

    /** @type {Option|Group} */
    return options.find(option => option?.id === id);
}

/**
 * Look up an optionSet based on its id, throwing an error if the required optionSet isn't found.
 * @param {Item} item - The item for which to look up the optionSet
 * @param {OptionSet[]} optionSets - The list of optionSets available in the survey
 * @return {?OptionSet} - Returns the matching OptionSet object, or null if the requested optionSetId was null
 * @throws {Error} - Throws an exception if the required optionSet wasn't in the list of available optionSets
 */
export function getOptionSet(item, optionSets) {
    // When the requested id is null, or the list of optionSets isn't an array, returned optionSet is likewise null.
    if (!item?.optionSet || !(optionSets instanceof Array)) return null;

    /** @type {OptionSet} - Lookup up the desired optionSet based on its ID */
    const matchingOptionSet = optionSets.find((optionSet) => optionSet['id'] === item?.optionSet);

    /** @throws {Error} - Throw an exception if no matching optionSet was found */
    if (!matchingOptionSet) {
      console.log('Failed to find option set '+item?.optionSet+' for item '+item?.id+'. The survey must not have loaded correctly.');
      throw new Error('Could not load the optionset for that item.')
    }

    return matchingOptionSet;
}

/**
 * Get the ids of the currently selected options or groups for a given reply object
 * @param {(Answer|Demographic)} reply - The reply for which to retrieve the selected options
 * @return {string[]}
 */
export function getSelectedOptions(reply) {
    if (reply?.options?.length > 0) {
        return reply.options;
    } else if (reply?.groups?.length > 0) {
        return reply.groups;
    }
    return [];
}

/**
 * Produce a new list of selected options
 * @param {number} maxSelections - The maximum number of options allowed to be selected for the given element
 * @param {string} optionId - The id of the newly selected option
 * @param {string[]} selectedOptionIds - The current list of selected options
 * @return {string[]}
 */
export function determineSelectedOptions(maxSelections, optionId, selectedOptionIds) {
    /** @type {boolean} - Whether the give option is currently in the list of selectedOptions */
    const isActive = selectedOptionIds.includes(optionId);

    if (isActive && maxSelections > 1) {
        // When more than one selection is allowed, picking an active option removes it from the selected options
        return selectedOptionIds.filter((selectedOptionId) => selectedOptionId !== optionId)
    } else if (maxSelections === 1) {
        // When only one selection is allowed, picking an option sets it as the only selected option
        return [optionId]
    }
    // When more than one selection is allowed, picking a non-active option adds it to the list of selected options
    return [...selectedOptionIds, optionId];
}

/**
 * Determine whether the input field should be visible or hidden
 * @param {string} format - The format of the item. INP stands for input format.
 * @param {string[]} optionsTriggeringInput - The list of option ids that cause the input field to be visible
 * @param {string[]} selectedOptions - The list of ids for selected options
 * @return {boolean}
 */
export function determineInputVisibility(format, optionsTriggeringInput, selectedOptions) {
    if (format === 'INP' || format === 'RIN') return true;

    return selectedOptions.some((option) =>
        optionsTriggeringInput.includes(option)
    );
}


/**
 * Determine the type of input element to display on the page.
 * @param {?string} inputType - The type property of the Input object
 * @param {boolean} isVisible - Whether the input is visible
 * @return 'blank'|'file'|'floating'
 */
export function determineInputComponentType(inputType, isVisible) {
    if (!inputType || !isVisible) {
        return 'blank';
    } else if (inputType === 'file') {
        return 'file';
    } else if (inputType === 'submit') {
        return 'submit';
    }
    return 'floating';
}